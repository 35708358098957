/* App */
@font-face {
    font-family: SFProFont;
    src: url(assets/fonts/FontsFree-Net-SFProDisplay-Medium.ttf);
}

.App {
    text-align: center;
    font-family: SFProFont !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.border-content {
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
}

.org-info-content {
    border-bottom: 1px solid #E5E5E5;
    height: 65px;
}

.menu-content {
    border-right: 1px solid #E5E5E5;
}

.ts-connect .item-label,
.ts-setting .item-label,
.provider-settings .item-label, .log-admin .item-label {
    font-size: 12px;
    font-weight: bold;
    /* padding: 9px 0px */
}

.provider-settings p.hint {
    line-height: 29px;
    text-align: left;
    font-size: 13px;
    letter-spacing: 0px;
    color: #181818;
    margin-left: 15px;
    margin-bottom: 0;
}

.provider-settings .btn-screen-password, .work-location .btn-add-setting {
    font-size: 13px;
    letter-spacing: 0;
    color: #0176D3;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E5E5E5 !important;
    border-radius: 2px;
}

.provider-settings .btn-screen-password:hover, .work-location .btn-add-setting:hover {
    background-color: #F3F3F3 !important;
    color: #014486 !important;
}

.checkbox-label {
    font-size: 13px;
    min-width: 85px;
}

.button-search {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #C9C7C5 !important;
    border-radius: 4px !important;
    color: #0176D3 !important;
    font-size: 13px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 30px;
    min-width: 61px;
}

.button-search:hover {
    background: #F3F3F3 0% 0% no-repeat padding-box !important;
    color: #014486 !important;
}

.modal-footer {
    background-color: #F3F2F2 !important;
}

.modal-btn {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 12px !important;
    width: 85px;
    border-radius: 2px !important;
}

.modal-cancel-btn {
    color: #0176D3 !important;
    border: 1px solid #E5E5E5 !important;
}

@media only screen and (min-width: 1700px) {
    .col-2 {
        width: 20% !important;
    }

    .col-1 {
        width: 10% !important;
    }

    .col-auto {
        width: auto !important;
    }
}

/* End App */

/* header */
.ts-logo {
    width: 45px;
}

.admin-header {
    background-color: #EEF4FF;
}

.admin-back-btn {
    background-color: #FFFFFF !important;
    color: #0176D3 !important;
}

.header-title {
    color: #181818;
    font-size: 18px !important;
    font-weight: 700 !important;
}

/* End header */

/* org-info */
.org-info {
    display: flex;
    justify-content: space-between;
    padding: 20px 12px;
}

.org-info__items {
    display: flex;
    gap: 24px;
}

.org-info__items .item-label {
    font-size: 12px;
    color: #181818;
}

.org-info__items .item-value {
    font-size: 14px;
    font-weight: bold;
    color: #181818;
}

.logout-btn {
    font-size: 13px;
    color: #0176D3;
    text-decoration: none;
    cursor: pointer;
}

.admin-info-bar {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
}

/* default */

.user-main-content {
    min-height: calc(100vh - 64px - 65px);
}

.admin-main-content {
    min-height: calc(100vh - 64px - 65px);
}

.admin-view-main-content {
    min-height: calc(100vh - 89px - 65px);
}

.nav-pre {
    width: 4px;
}

.nav-suf {
    flex: 1;
    font-size: 16px !important;
    color: #080707 !important;
}

.nav-item:hover .nav-pre {
    background-color: #EEF4FF !important;
}

.nav-item:hover .nav-suf {
    background-color: #EEF4FF !important;
}

.nav-item__selected .nav-pre {
    width: 4px;
    background-color: #07487E !important;
}

.nav-item__selected .nav-suf {
    background-color: #EEF4FF !important;
}

.nav-item__selected:hover .nav-pre {
    background-color: #07487E !important;
}

.settings-content {
    background-color: #F3F3F3;
}

.scroll-content {
    overflow-y: scroll;
}

.user-scroll-content {
    max-height: calc(100vh - 64px - 65px);
}

.admin-scroll-content {
    max-height: calc(100vh - 64px - 65px);
}

.admin-view-scroll-content {
    max-height: calc(100vh - 89px - 65px);
}

.radio-form-check {
    min-height: unset !important;
}

/* End default */

/* card-container */
.card-container {
    border-radius: 2px !important;
}

/* End card-container */

/* provider-setting */
.provider-settings .btn-save {
    background-color: #0176D3;
}

.provider-settings .btn-export {
    background-color: #FFFFFF !important;
    color: #0176D3 !important;
}

.provider-settings .btn-export:hover {
    background-color: #F3F3F3 !important;
    color: #014486 !important;
}

.provider-settings .btn-disabled {
    background-color: #F3F3F3 !important;
    border: #E5E5E5;
    color: #706E6B !important;
    pointer-events: none;
}

.provider-settings .export-time {
    display: flex;
    align-items: center;
    font-size: 13px;
}

.provider-settings .auto-term-select-box {
    width: 154px;
}

/* End provider-setting */

/* Page log reports */
.log-reports .table-logs, .log-admin .table-logs {
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5
}

.log-reports .log-form-label, .log-admin .log-form-label {
    font-size: 12px;
    color: #181818;
    min-width: max-content;
}

.log-reports .log-form-control, .log-admin .log-form-control {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #181818;
}

.tool-tip {
    position: relative;
    display: inline-block;
}

.tool-tip .info-icon-text {
    visibility: hidden;
    background-color: #014486;
    font-size: 12px;
    color: #FFFFFF;
    border-radius: 4px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    top: 140%;
    left: -130px;
    /* margin-left: -200px; */
    text-align: start;
}

.tool-tip .info-icon-text::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 132px;
    /* margin-left: -5px; */
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent #014486 transparent;
}

.tool-tip .info-icon-text-left {
    visibility: hidden;
    background-color: #014486;
    font-size: 12px;
    color: #FFFFFF;
    border-radius: 4px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    top: 140%;
    left: -10px;
    /* margin-left: -100%; */
    text-align: start;
}

.tool-tip .info-icon-text-left::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 12px;
    /* margin-left: -5px; */
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent #014486 transparent;
}

.tool-tip:hover .info-icon-text,
.tool-tip:hover .info-icon-text-left {
    visibility: visible;
}

.tool-tip:hover .info-icon {
    fill: #014486 !important;
}

.log-reports thead, .log-admin thead {
    background: #FAFAF9 0% 0% no-repeat padding-box;
}

.log-reports thead th, .log-admin thead th {
    font-size: 14px;
    font-weight: bold;
    color: #181818;
}

.log-reports tbody, .log-admin tbody {
    font-size: 13px;
    border: 1px solid #E5E5E5;
}

.log-reports tbody td, .log-admin tbody td {
    border: none;
    border-bottom: 2px solid #E5E5E5;
}

.log-reports .error-level, .log-admin .error-level {
    background-color: #FEF0F3;
    color: #EA001E;
    padding: 0px 8px;
    border-radius: 8px;
}

input.error {
    border: 1px solid #EA001E !important;
}

.cursor-pointer {
    cursor: pointer;
}

span.required {
    color: #ea001e;
}

.log-reports .warn-level, .log-admin .warn-level {
    background-color: #F9E3B6;
    padding: 0px 8px;
    border-radius: 8px;
}

.log-reports .info-level, .log-admin .info-level {
    background-color: #EBF7E6;
    padding: 0px 12px;
    border-radius: 8px;
}

.log-admin .info-batch-level {
    background-color: #EEF4FF;
    padding: 0px 12px;
    border-radius: 8px;
}

.log-reports thead .btn-sort, .log-admin thead .btn-sort {
    background: unset;
}

.log-reports thead .xs-column, .log-admin thead .xs-column {
    width: 10%;
}

.log-reports thead .lg-column, .log-admin thead .lg-column {
    width: 70%;
}

.log-reports .pagination, .log-admin .pagination {
    font-size: 13px;
}

.log-reports .pagination li, .log-admin .pagination li {
    margin-left: 10px;
}

.log-reports .page-link:hover, .log-admin .page-link:hover {
    cursor: pointer;
    background-color: #C9C7C5;
}

/* End Page log reports */

/* admin-console*/
.admin-console .btn-export {
    font-size: 13px;
    background-color: #FFFFFF !important;
    color: #0176D3 !important;
}

.admin-console .btn-export:hover {
    background-color: #F3F3F3 !important;
    color: #014486 !important;
}

.admin-console .btn-register {
    font-size: 13px;
    cursor: pointer;
    color: #0176D3 !important;
}

.admin-console .btn-register:hover {
    color: #0a58ca !important;
}

.admin-console .btn-detail {
    font-size: 13px;
    background-color: #0176D3;
}

.admin-console thead {
    background: #FAFAF9 0% 0% no-repeat padding-box;
}

.admin-console thead tr {
    vertical-align: top;
}

.admin-console thead th {
    font-size: 14px;
    font-weight: bold;
    color: #181818;
    min-width: 86px;
}

.admin-console tbody td {
    font-size: 13px;
    color: #181818;
}

.admin-console .table-organizations {
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
}

.admin-console .admin-form-label {
    font-size: 13px;
    color: #181818;
    min-width: 52px;
}

.admin-console .admin-form-control {
    flex: 1;
}

.admin-console .admin-form-checkbox {
    font-size: 13px;
    color: #181818;
    min-height: 0 !important;
    margin-bottom: 0px !important;
}

.admin-console .pagination {
    font-size: 13px;
}

.admin-console .pagination li {
    margin-left: 10px;
}

.admin-console .disabled-account, .admin-console .disabled-account tr, .admin-console .disabled-account td {
    background-color: #C9C9C9;
}

.admin-console .page-link:hover {
    cursor: pointer;
    background-color: #C9C7C5;
}

/* End admin-console*/

.btn-submit {
    min-width: 90px;
}

.btn-connect {
    color: #0176D3;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E5E5E5;
    min-width: 100px;
    cursor: pointer;
    border-radius: 2px;
    font-size: 13px;
    height: 27px;
    line-height: 23px;
}

.btn-connect:hover {
    background-color: #F3F3F3 !important;
    color: #014486 !important;
}

.ts-connect {
    font-size: 13px;
}

.ts-connect thead .xs-column {
    width: 15%;
}

.ts-connect thead .lg-column {
    width: 55%;
}

.btn-disconnect {
    font-size: 13px;
    min-width: 100px;
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border: 1px solid #E5E5E5;
    cursor: pointer;
    border-radius: 2px;
    color: #706E6B;
    line-height: 23px;
    height: 27px;
}

.not-allowed {
    cursor: not-allowed !important;
    color: #706E6B !important;
}

.admin-not-allowed {
    cursor: not-allowed !important;
}

.page-title {
    font-weight: 600;
    font-size: 16px;
}

.page-sub-title {
    font-size: 14px;
    font-weight: bold;
    color: #444444;
}

.form-control {
    height: 32px !important;
}

.list-group-item .form-select, .list-group-item .form-control {
    font-size: 13px;
}

.input-time {
    width: 120px !important;
}

.input-ip-address {
    min-width: 150px !important;
}

.fs-13 {
    font-size: 13px;
}

.login-container {
    background: #F3F3F3;
}

.admin-login-container {
    background: #EEF4FF;
}

.btn-login {
    background: #0176D3 0% 0% no-repeat padding-box;
    border-radius: 2px;
    height: 48px;
    color: #fff;
    font-size: 18px;
    line-height: 46px;
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    width: 210px;
}

.btn-login:hover {
    cursor: pointer;
    color: #fff
}

.login-radio {
    font-size: 13px;
}

.description {
    font-family: SFProFont;
    color: #181818;
    font-weight: bold;
    font-size: 28px;
    text-align: center;
}

.ts-login-logo {
    width: 180px;
}

p.error {
    font-size: 12px;
    color: #EA001E;
    padding-top: 10px;
}

.form-check .form-check-input:checked {
    color: #969492 !important;
    background: #fff;
    border-color: #969492 !important;
    font-family: SFProFont !important;
}

.form-check-label {
    font-family: SFProFont !important;
    font-size: 13px;

}

.form-check .form-check-input:disabled {
    background: #fff;
    color: #969492 !important;
    border-color: #969492 !important;
}

.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2'  fill='%230176D3'/%3e%3c/svg%3e") !important;
}

.form-check-input:checked:disabled[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23969492'/%3e%3c/svg%3e") !important;
}

.btn-export-disable {
    background: #F3F3F3 !important;
    border: 1px solid #E5E5E5 !important;
    border-radius: 2px !important;
    opacity: 1;
    color: #706E6B !important;
}

/* stations */
.stations .station-form-label {
    font-size: 13px;
    color: #181818;
    min-width: 52px;
}

.stations .station-form-control {
    flex: 1;
}

.stations .station-form-checkbox {
    font-size: 13px;
    color: #181818;
    min-height: 0 !important;
    margin-bottom: 0px !important;
}

.stations .table-stations {
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
}

.stations thead {
    background: #E3EBF1 0% 0% no-repeat padding-box;
}

.stations thead th {
    font-size: 14px;
    font-weight: bold;
    color: #2E5E7E;
    min-width: 86px;
}

.stations tbody td {
    font-size: 13px;
    word-wrap: break-word;
    color: #181818;
    vertical-align: middle;
    word-break: break-all;
}

.stations .add-station-btn {
    font-size: 13px;
    color: #0176D3;
    text-decoration: none;
    cursor: pointer;
    width: 80px
}

.stations .add-station-btn:hover {
    color: #014486;
}

.stations .add-station-btn:hover .plus-icon {
    fill: #014486;
}

.stations .btn-register {
    font-size: 13px;
    cursor: pointer;
    color: #0176D3 !important;
}

.stations .btn-register:hover {
    color: #0a58ca !important;
}

.modal-form-label {
    font-size: 12px;
    color: #181818;
    min-width: 70px;
}

.modal-form-control {
    flex: 1;
    font-size: 12px !important;
}

.stations .btn-detail-disabled {
    color: #706E6B !important;
    font-size: 13px;
    background-color: #F3F3F3 !important;
    border-color: #F3F3F3 !important;
}

.stations .btn-detail {
    font-size: 13px;
    background-color: #0176D3;
}

.stations .btn-export-csv {
    font-size: 13px;
    background-color: #FFFFFF !important;
    border: 1px solid #0176D3 !important;
    border-radius: 2px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 30px;
}

.stations .btn-export-csv:hover {
    background-color: #F3F3F3 !important;
    color: #014486 !important;
}

.stations .btn-export-csv:hover .download-icon {
    fill: #014486 !important;
}

/* End stations */

/* create-org */
.create-org .card-label {
    font-size: 14px;
    font-weight: bold;
}

.create-org .item-label {
    font-size: 12px;
    font-weight: bold;
}

.create-org .btn-save {
    background-color: #0176D3;
}

.admin-console .btn-disabled {
    background-color: #F3F3F3 !important;
    border: #E5E5E5;
    color: #706E6B !important;
    pointer-events: stroke;
}

.create-org .btn-disabled {
    background-color: #F3F3F3 !important;
    border: #E5E5E5;
    color: #706E6B !important;
    pointer-events: none;
}

.admin-console .not-allowed,
.create-org .not-allowed {
    cursor: not-allowed !important;
    color: #706E6B !important;
    background: #F3F3F3 0% 0% no-repeat padding-box;
}

.create-org .admin-form-label {
    font-size: 13px;
    color: #181818;
    min-width: 52px;
}

/* End create-org */

.table-scroll {
    max-width: 1600px;
    overflow-x: scroll;
}

.admin-console .table-scroll table {
    min-width: 2100px;
}

.stations .table-scroll table {
    min-width: 1600px;
}

.bg-exists-station {
    background: #C9C9C9;
}

.page-link:hover {
    cursor: pointer;
    background-color: #C9C7C5 !important;
}

.pagination {
    font-size: 13px;
}

.modal-preview-icon {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.react-datepicker-wrapper {
    width: 100%;
}

.text-btn-back-password {
    color: #0176D3 !important;
    border: 1px solid #E5E5E5 !important;
}

.log-admin .react-datepicker-wrapper, .log-admin .input-account-name {
    max-width: 200px;
}

.btn-back-sandbox {
    color: #747474;
    border: 1px solid #E5E5E5;
    line-height: 16px;
    font-size: 13px;
    font-weight: 400;
    border-radius: 5px;
    padding: 6px 13px;
    text-decoration: none;
}

.img-close-button {
    position: relative;
    top: -1px;
    display: inline-block;
    margin-right: 4px;
}

.env-sandbox-body {
    background-image: url("./assets/images/sandbox-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.env-sandbox-header {
    background-image: url("./assets/images/sandbox-bg-header.png");
    text-align: center;
    padding: 8px 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.env-sandbox-header h4, .env-sandbox-header h4 a {
    color: #fff;
    margin-bottom: 0;
    font-weight: 700;
    font-size: 13px;
}

.pittouch-ex-setting .tab-company {
    padding: 24px 12px;
    border-color: var(--bs-border-color);
    border-width: 1px;
    border-style: solid;
    border-top: none;
}


.pittouch-ex-setting .tab-company .btn-add-company {
    text-decoration: none;
    color: #0176D3;
    font-size: 13px;
    padding-left: 10px;
}

.pittouch-ex-setting .tab-company .btn-add-company svg {
    position: relative;
    top: -2px;
    right: 5px;
}

.pittouch-ex-setting .tab-company .btn-add-company svg path {
    fill: #969492
}

.pittouch-ex-setting .tab-company .btn-add-company:hover {
    cursor: pointer;
}

.pittouch-ex-setting .tab-company .btn-add-company:hover svg path {
    fill: #014486;
}


.pittouch-ex-setting .tab-company .table-company thead {
    border: 1px solid #E5E5E5;
}

.pittouch-ex-setting .tab-company .btn-add-company-disable:hover svg path {
    fill: #969492;
}

.btn-add-company-disable {
    color: #969492 !important;
}

.btn-add-company-disable:hover {
    cursor: not-allowed;
}

.pittouch-ex-setting .tab-company .table-company thead th {
    background: #FAFAF9;
    color: #181818;
    font-weight: 600;
    font-size: 14px;
}

.pittouch-ex-setting .tab-company .table-company tbody td {
    font-size: 14px;
}


.tab-header-ex .nav-item .nav-link {
    font-size: 13px;
    color: #080707;
    line-height: 20px;
}

.tab-header-ex .nav-item .active {
    font-weight: 700;
}

.form-company .btn-remove-device-id {
    color: #EA001E;
    font-size: 13px;
    border-radius: 4px;
    border: 1px solid #E5E5E5;
    background: #FFF;
    display: inline-flex;
    height: 30px;
    padding: 5.5px 16px;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    position: absolute;
    right: 0;
    text-decoration: none;
}

.form-company .btn-add-device-id {
    color: #0176D3;
    font-size: 13px;
    border-radius: 2px;
    border: 1px solid #E5E5E5;
    background: #FFF;
    display: inline-flex;
    padding: 5.5px 16px;
    align-items: center;
    gap: 6px;
    text-decoration: none;
}

.form-company .btn-remove-device-id:hover, .form-company .btn-add-device-id:hover, .form-company .ref-new-pattern:hover {
    cursor: pointer;
}

.ref-new-pattern {
    color: #0176D3;
    font-size: 13px;
    text-decoration: none;
}

.form-company .select-pattern {
    font-size: 13px;
}

.pittouch-ex-setting .label-title {
    font-size: 13px;
    color: #0176D3;
    font-weight: 400;
    text-decoration: none;
}

.pittouch-ex-setting .label-title:hover {
    cursor: pointer;
}

.qr-settings .item-label {
    display: flex;
    align-items: center;
}

.qr-settings.status-mode-title {
    display: flex;
    align-items: center;
}

.qr-settings .add-meal-reservation {
    display: block;
    float: right;
    color: #0176D3;
    font-size: 13px;
    margin-right: 50px;
}

.qr-settings .add-meal-reservation:hover {
    cursor: pointer;
}

.qr-settings .meal-settings {
    min-height: 34px;
}

.btn-remove-meal-reservation {
    font-size: 13px;
    color: #EA001E;
    border-radius: 4px;
    border: 1px solid #E5E5E5;
    padding: 5.5px 16px 5.5px 16px;
    gap: 6px;
}

.btn-remove-meal-reservation:hover {
    cursor: pointer;
}

.form-reservation-array {
    font-size: 13px;
}

.form-reservation-array p {
    margin-bottom: 0;
}

.form-reservation-array {
    border-bottom: 1px solid #E5E5E5
}

.form-password-qr {
    font-size: 12px;
}

.list-device {
    background: #FAFAF9;
}

.list-device .list-device-form {
    background: none;
}

.text-error-meal-reservations {
    margin-left: 105px;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.btn-remove-setting {
    font-size: 13px;
    color: #EA001E;
    position: absolute;
    cursor: pointer;
    right: 15px;
}

.btn-remove-ip-restrict {
    left: 10px;
}

.btn-delete-logo {
    font-size: 13px;
    margin-left: 14.5px;
    color: #EA001E;
    cursor: pointer;
}

.name-meal-reservations {
    width: 90px;
    display: flex;
}

.name-meal-reservations .name-meal-reservations-index {
    width: 20px;
}

.name-meal-reservations .name-meal-reservations-title {
    width: 70px;
}

.btn-group-sort {
    height: 32px;
}

.btn-group-sort .btn-sort {
    height: 32px;
    background: #fff;
    border: 1px solid #E5E5E5;
}

.btn-group-sort .btn-sort-down svg {
    transform: rotate(180deg);
}

.btn-group-sort .btn-sort:hover {
    background: #fff;
    border: 1px solid #E5E5E5;
}

.btn-sort-disabled {
    background: #E5E5E5 !important;
}

.btn-sort-disabled path {
    fill: #C9C9C9 !important
}

.status-mode-title select {
    height: 34px;
}

.btn-upload-logo {
    padding: 1px 16px;
    border: 1px solid #747474;
    font-size: 13px;
    border-radius: 4px;
    color: #0176D3;
    line-height: 20px;
}

.file-upload {
    text-align: center;
    border: 1px dashed #C9C9C9;
    padding: 1px;
    position: relative;
    cursor: pointer;
}

.file-upload p {
    font-size: 0.87rem;
    margin-top: 10px;
    color: #bbcada;
}

.file-upload input {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
}

.img-logo {
    outline: 1px solid #E5E5E5;
    border-radius: 10px;
    width: 94px;
    height: 94px;
}

.w-60 {
    width: 60% !important;
}

.modal-gen-qrcode .p-desc {
    font-size: 12px;
    line-height: 18px;
}

.modal-gen-qrcode .filter-title {
    font-size: 13px;
}

.modal-gen-qrcode .btn-screen-password {
    font-size: 13px;
    letter-spacing: 0;
    color: #0176D3;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E5E5E5 !important;
    border-radius: 2px;
}

.modal-gen-qrcode .btn-screen-password:hover {
    background-color: #F3F3F3 !important;
    color: #014486 !important;
}

.modal-gen-qrcode .btn-reset-filter {
    font-size: 12px;
    color: #0176D3;
    line-height: 18px;
    cursor: pointer;
}

.table-employee thead tr th {
    background: #F3F3F3;
    border: 0;
    font-weight: bold;
    font-size: 13px;
}

.table-employee th:first-child, .table-employee tbody tr td:first-child {
    border-radius: 2px 0 0 0;
}

.table-employee th:last-child, .table-employee tbody tr td:last-child {
    border-radius: 0 2px 0 0;
}

.table-employee {
    border-radius: 2px;
    border: 1px solid #E5E5E5;
}

.table-employee tbody {
    font-size: 13px;
}

.modal-gen-qrcode .btn-sort-asc, .modal-gen-qrcode .btn-sort-desc {
    cursor: pointer;
}

.modal-gen-qrcode .btn-sort-asc {
    transform: rotate(180deg);
}

.modal-gen-qrcode .modal-cancel-btn {
    background: #fff;
}

.table-employee tbody {
    display: block;
    height: 365px;
    overflow-y: auto;
}

.table-employee thead, .table-employee tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.table-employee tbody tr td {
    text-align: left;
}

.content-emp {
    height: 400px;
}

.list-item-ip-restrict {
    margin-top: 12px
}

.work-location .radio-form-check {
    width: 85px;
    min-height: 1px;
}

.work-location .form-input-default {
    border-bottom: 1px solid #E5E5E5;
}

.work-location .form-control-input {
    width: 200px;
}

.work-location .form-input-default-label {
    font-size: 13px;
    color: #181818;
}

.work-location .form-input-default-label {
    font-size: 13px;
    color: #181818;
}

.work-location .label-default-worklocation {
    background: #C9C9C9;
    color: #181818;
    font-weight: bold;
    font-size: 12px;
    border-radius: 23px;
    padding: 2px 8px;
}

.work-location .form-input-checkbox label {
    font-size: 13px;
}

.form-list-settings {
    padding: 12px;
    border-radius: 4px;
    background: #F3F3F3;
}

.work-location .desc-setting p {
    font-size: 13px;
}

.work-location .form-setting-content input {
    width: 125px;
}

.work-location .btn-remove-worklocation {
    font-size: 13px;
    color: #EA001E;
    cursor: pointer;
    right: 15px;
}

.w-label-reservation {
    width: 91px;
}

.form-title-reservation {
    margin-left: 25px;
}

.form-title-reservation .form-control {
    min-width: 400px;
}

.w-label-reservation-description {
    margin-left: 42px;
}

/*Attendance log*/
.attendance-log .btn-save {
    background-color: #0176D3;
}

.attendance-log .log-form-label {
    color: #444444;
    font-weight: 600;
}

.attendance-log .calendar-icon {
    background: #FFFFFF url("./assets/icons/calendar.svg") no-repeat 95% 50%;
}

.table-attendance .status-unsent {
    color: #EA001E;
}

.table-attendance .heading {
    background-color: #FAFAF9;
}

.table-attendance .btn-sort-desc {
    cursor: pointer;
}

.table-attendance .btn-sort-asc {
    transform: rotate(180deg);
}

.qr-settings .tab-meals, .qr-settings .tab-content {
    border: 1px solid #DDDBDA;
}

.qr-settings .tab-meals li:first-child .active, .qr-settings .tab-meals li:first-child .active {
    border-top: 0;
    border-left: 0;
}

.qr-settings .tab-meals li:hover {
    border-top: 0;
    border-left: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.qr-settings .tab-meals li button {
    font-size: 13px;
    color: #000000;
    border-top: 0;
    border-left: 0;
}

.qr-settings .tab-meals li button.active {
    border-top: 0;
    color: #080707;
    font-size: 13px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    font-weight: 700;
    border-left: 1px solid #DDDBDA;

}

.qr-settings .tab-meals {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}


.qr-settings .nav-tabs .nav-link {
    margin-bottom: calc(-2 * var(--bs-nav-tabs-border-width));
}

.qr-settings .tab-content {
    border-top: 0;
}

.qr-settings .border-active-tab {
    border-bottom: 1px solid #E5E5E5;
}